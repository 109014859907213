import { onError } from './onError';

import { storage } from '../storage';
import { ACTIONS, ACTION_USED } from '../../constants/paymentMethodsHub';

const response = {
  providerMessage: 'testErrorMsg',
};

const refreshPaymentMethodsCollectionMock = jest.fn();
const onAddPaymentMethodErrorMock = jest.fn();

const commonEmitMock = jest.fn();

const composablePayload = {
  contextData: {
    isRedirected: false,
    flowTracking: {
      currentAction: ACTIONS.add,
      addIsEmbedded: false,
    },
  },
  commonEmit: (param, value) => {
    commonEmitMock(param, value);
  },
  originalResponse: response,
  parentActionCallback: param => {
    switch (param) {
      case 'refreshPaymentMethodsCollection':
        refreshPaymentMethodsCollectionMock();
        break;

      case 'onAddPaymentMethodError':
        onAddPaymentMethodErrorMock();
        break;

      default:
        break;
    }
  },
};

describe('When the "onError" method is called', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  afterEach(() => {
    jest.resetAllMocks();
    storage.remove(ACTION_USED);
  });

  describe('And the action is add', () => {
    it('Then the "onAddPaymentMethodError" is called', () => {
      composablePayload.contextData.flowTracking = {
        currentAction: 'add',
      };
      onError(composablePayload);

      expect(onAddPaymentMethodErrorMock).toHaveBeenCalled();
    });
  });

  describe('And the action is list', () => {
    it('Then the "on:payment-method-added" event is emitted', () => {
      composablePayload.contextData.flowTracking = {
        currentAction: 'list',
      };
      onError(composablePayload);

      expect(commonEmitMock).toHaveBeenCalledWith('on:add-payment-method-error', undefined);
    });
  });

  describe('And the action is payment', () => {
    it('Then the "on:payment-error" event is emitted', () => {
      composablePayload.contextData.flowTracking = {
        currentAction: 'payment',
      };
      onError(composablePayload);

      expect(commonEmitMock).toHaveBeenCalledWith('on:payment-error', 'testErrorMsg');
    });
  });
});
